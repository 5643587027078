.notification-details-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.notification-details-container > * {
    margin: 8px;
    font-size: 18px;
}

.notification-details-container .fields {
    text-align: end;
}

.notification-details-container .values {
    text-align: start;
}
