p.phones-table-heading {
    text-align: start;
    font-size: 20px;
}

table tbody td:nth-child(1) {width: 15px; }
table tbody td:nth-child(2) {width: 250px; }
table tbody td:nth-child(3) {width: 15px; }
table tbody td:nth-child(4) {width: 150px; }
table tbody td:nth-child(5) {width: 25px; }

li {
    margin: 0 16px;
}
