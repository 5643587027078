.container > div {
    margin-bottom: 24px !important;
}

.radio-buttons {
    display: flex;
    justify-content: center;
}
.radio-buttons > div {
    margin: 0 16px;
}
