.notifications-headed-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;
}
.notifications-headed-block .buttons {
    display: flex;
    align-items: center;
}

p.table-heading {
    text-align: start;
    font-size: 18px;
}

table tbody td:nth-child(1) {width: 15px; }
table tbody td:nth-child(2) {width: 275px; }
table tbody td:nth-child(3) {width: 75px; }
table tbody td:nth-child(4) {width: 15px; }
table tbody td:nth-child(5) {width: 15px; }

table tbody tr:hover {
    cursor: pointer;
    background-color: #e1e0e0;
}
li {
    margin: 0 16px;
}
